exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-customization-tsx": () => import("./../../../src/pages/customization.tsx" /* webpackChunkName: "component---src-pages-customization-tsx" */),
  "component---src-pages-faq-tsx": () => import("./../../../src/pages/faq.tsx" /* webpackChunkName: "component---src-pages-faq-tsx" */),
  "component---src-pages-guide-tsx": () => import("./../../../src/pages/guide.tsx" /* webpackChunkName: "component---src-pages-guide-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-legal-tsx": () => import("./../../../src/pages/legal.tsx" /* webpackChunkName: "component---src-pages-legal-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-single-tsx": () => import("./../../../src/pages/single.tsx" /* webpackChunkName: "component---src-pages-single-tsx" */),
  "component---src-templates-customization-item-meta-slug-tsx": () => import("./../../../src/templates/customization/{item.meta__slug}.tsx" /* webpackChunkName: "component---src-templates-customization-item-meta-slug-tsx" */)
}

